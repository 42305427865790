import DateFormatter from './DateFormatter'
import Month from './Month'
import { today, currentMonth } from './now'

export default {
	name: 'Calendar',
	props: {
		today: {
			type: Date,
			default: today
		},
		month: {
			type: Date,
			default: currentMonth
		},
		attrs: {
			type: Array,
			default: []
		},
		formatter: {
			type: Function,
			default: DateFormatter
		}
	},
	data () {
		return {
			aMonth: this.month
		}
	},
	components: {
		Month
	},
	template: `
		<div class="bs-cal-container">
			<div class="bs-cal-grid">
				<Month
					:month="aMonth"
					:today="today"
					:attrs="attrs"
					:formatter="formatter"
					@setMonth="setMonth"
				/>
			</div>
		</div>
	`,
	methods: {
		setMonth (month) {
			this.aMonth = month
			this.$emit('setMonth', month)
		}
	}
}
