import { IconPrev, IconNext } from './Icons'
import DateUtil from './DateUtil'

export default {
	name: 'Month',
	props: {
		month: {
			type: Date,
			required: true
		},
		today: {
			type: Date,
		},
		attrs: {
			type: Array,
			default: []
		},
		formatter: {
			type: Function,
			required: true
		}
	},
	data () {
		return {
			weekdays: this.getWeekdays()
		}
	},
	components: {
		IconPrev,
		IconNext
	},
	template: `
		<div class="bs-cal-month">
			<div class="bs-cal-header">
				<div class="bs-cal-title">
					<span class="year">
						{{yearStr}}<span class="unit">年</span>
					</span>
					<span class="month">
						{{monthStr}}<span class="unit">月</span>
					</span>
				</div>
				<button type="button" class="bs-cal-nav-btn bs-cal-nav-prev"
					aria-label="前の月"
					@click="prevMonth"
				>
					<IconPrev />
				</button>
				<button type="button" class="bs-cal-nav-btn bs-cal-nav-next"
					aria-label="次の月"
					@click="nextMonth"
				>
					<IconNext />
				</button>
			</div>
			<div class="bs-cal-body">
				<div class="bs-cal-row bs-cal-row-day">
					<div v-for="wday in weekdays" :key="wday.id"
						class="bs-cal-col bs-cal-col-dow"
						:class="'dow-'.concat(wday.id)"
					>
						<span class="bs-cal-label-weekday">{{wday.name}}</span>
					</div>
				</div>
				<div v-for="week in weeks" :key="week.id"
					class="bs-cal-row bs-cal-week"
				>
					<div v-for="date in week.dates" :key="date.id"
						class="bs-cal-col"
						:class="getClasses(date)"
					>
						<span class="bs-cal-num" :data-date="date.str">{{date.num}}</span>
					</div>
				</div>
			</div>
		</div>
	`,
	methods: {
		prevMonth () {
			const current = this.month
			const month = new Date(current.getFullYear(), current.getMonth() - 1, 1)
			this.$emit('setMonth', month)
		},
		nextMonth () {
			const current = this.month
			const month = new Date(current.getFullYear(), current.getMonth() + 1, 1)
			this.$emit('setMonth', month)
		},
		getWeekdays () {
			// 2011-05-08 = 日曜日
			const wdayStrs = []
			for (let date = 8; date < 15; date++) {
				// eslint-disable-next-line new-cap
				const formatter = new this.formatter(new Date(2011, 4, date))
				wdayStrs.push({
					id: date - 8,
					name: formatter.weekday()
				})
			}
			return wdayStrs
		},
		getClasses (date) {
			const classes = []

			// 曜日
			classes.push('dow-'.concat(date.date.getDay()))

			// 表示月以外の日
			if (date.outOfMonth) classes.push('bs-cal-col-pad')

			// 今日、過去の日付
			const cmp = DateUtil.compare(date.date, this.today)
			if (cmp === 0) classes.push('bs-cal-col-today')
			if (cmp < 0) classes.push('bs-cal-col-past')

			// ハイライト
			this.attrs.forEach(attr => {
				if (!attr.highlight) return
				if (!Array.isArray(attr.dates)) throw new Error('attr.dates must be array of Date.')
				if (!attr.dates.some(d => DateUtil.equal(date.date, d))) return
				classes.push('bs-cal-col-hl')
				classes.push('bs-cal-col-hl-'.concat(attr.highlight))
			})

			return classes.join(' ')
		},
		getDateString (date) {
			return [
				date.getFullYear(),
				'0'.concat(date.getMonth() + 1).slice(-2),
				'0'.concat(date.getDate()).slice(-2)
			].join('-')
		}
	},
	computed: {
		weeks () {
			const weeks = []
			const { month } = this
			const nextMonth = new Date(month.getFullYear(), month.getMonth() + 1, 1)
			const begin = new Date(month.getFullYear(), month.getMonth(), 1 - month.getDay())
			const end = new Date(nextMonth.getFullYear(), nextMonth.getMonth(), 1 + ((7 - nextMonth.getDay()) % 7))
			let dates = []
			let id = 1;
			for (let date = begin; date < end; date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)) {
				dates.push({
					id,
					date,
					num: date.getDate(),
					str: this.getDateString(date),
					outOfMonth: date < month || date >= nextMonth
				})
				if (dates.length >= 7) {
					weeks.push({
						id: weeks.length,
						dates
					})
					dates = []
				}
				id++
			}
			if (dates.length > 0) {
				weeks.push(dates)
			}
			return weeks
		},
		yearStr () {
			// eslint-disable-next-line new-cap
			const formatter = new this.formatter(this.month)
			return formatter.year()
		},
		monthStr () {
			// eslint-disable-next-line new-cap
			const formatter = new this.formatter(this.month)
			return formatter.month()
		}
		// title () {
		// 	// eslint-disable-next-line new-cap
		// 	const formatter = new this.formatter(this.month)
		// 	return formatter.month()
		// }
	}
}
