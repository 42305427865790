/* global Vue */
import EventCalendar from './EventCalendar'
import { today, currentMonth } from './now'

function init () {
	const container = document.querySelector('.js-event-calendar')
	if (!container) return

	const monthStr = container.getAttribute('data-month')
	const month = (monthStr => {
		if (!monthStr) return currentMonth
		const match = monthStr.match(/^([0-9]{4})[/-]([0-9]{2})$/)
		if (!match) return currentMonth
		return new Date(parseInt(match[1], 10), parseInt(match[2], 10) - 1, 1)
	})(monthStr)

	const app = new Vue({
		el: container,
		components: {
			EventCalendar,
		},
		data () {
			return {
				month,
				today
			}
		},
		template: `
			<EventCalendar :month="month" :today="today" />
		`
	})
	return app
}

init()
