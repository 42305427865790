const DateUtil = {
	equal (x, y) {
		return x.getFullYear() === y.getFullYear() && x.getMonth() === y.getMonth() && x.getDate() === y.getDate()
	},
	compare (x, y) {
		if (!x || !y) return 1
		if (DateUtil.equal(x, y)) return 0
		return x < y ? -1 : 1
	}
}

export default DateUtil
